<template>
  <CRow>

    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-restaurant" />
          <h5 class="d-inline ml-2">Restaurants</h5>
          <div class="card-header-actions">
            <CButton color="primary" :to="{ name: 'RestaurantCreationWizard'}">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Restaurant
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "Restaurants",
  components: {
    BackendTable,
  },
};
</script>
